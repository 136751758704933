import { Model } from "@vuex-orm/core";

import store from "@/store";
import i18n from "@/setup/i18n";

import useDresskareGrpcClient from "@/composables/useDresskareGrpcClient";
import { CompanyController } from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/vendor_connect";
import { CompanyRequest } from "@dresskare/dresskare-grpc-api/connect/services/dresskare_back/vendor_pb";
const companyClient = useDresskareGrpcClient(CompanyController);

export default class Company extends Model {
  static entity = "company";

  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      title: this.string(),
      siret: this.string(),
      proofRegistration: this.string(),
      // vintedLink: this.string(),
      websiteLink: this.string(),
      taxEligible: this.boolean(false),
      negociatedPourcentageOnPrice: this.number(0),
    };
  }

  static getCompanies = async (filters) => {
    try {
      let metadata = {};
      if (filters) {
        metadata.filters = JSON.stringify(filters);
      }

      const response = await companyClient.list({}, { headers: metadata });

      if (!response.results) {
        return null;
      }
      this.insertOrUpdate({ data: response.results });
    } catch (error) {
      console.log("error:", error);
      return null;
    }
  };

  static create = async (form) => {
    try {
      const response = await companyClient.create(
        CompanyRequest.fromJson(form, { ignoreUnknownFields: true }),
        {}
      );

      this.insertOrUpdate({ data: response });

      store.dispatch(
        "notifications/showSuccessNotification",
        i18n.t("successCreate")
      );

      return response;
    } catch (error) {
      console.log("error:", error);
      store.dispatch("notifications/showErrorNotification", i18n.t("error"));
      return null;
    }
  };

  static getCompanyByUuid = async (companyUuid) => {
    const companyFromStore = this.find(companyUuid);

    if (companyFromStore) {
      return companyFromStore;
    } else {
      try {
        const response = await companyClient.retrieve(
          { uuid: companyUuid },
          {}
        );

        if (!response) {
          return null;
        }
        this.insertOrUpdate({ data: response });
        return response;
      } catch (error) {
        console.log("error:", error);
        return null;
      }
    }
  };

  static updateInDb = async (form) => {
    try {
      const response = await companyClient.update(
        CompanyRequest.fromJson(form, { ignoreUnknownFields: true }),
        {}
      );

      if (!response) {
        return null;
      }
      this.insertOrUpdate({ data: response });
      store.dispatch(
        "notifications/showSuccessNotification",
        i18n.t("successUpdate")
      );

      return response;
    } catch (err) {
      console.error(err);
      store.dispatch("notifications/showErrorNotification", i18n.t("error"));
      return null;
    }
  };
}
