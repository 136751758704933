<template>
  <VDialog
    :value="value"
    max-width="80%"
    persistent
    @input="$emit('input', $event)"
  >
    <VCard class="pb-3 pt-5 px-12">
      <VCardText class="px-7 mt-3 text-h4 text-center">
        {{ $t("exportInventory") }}
      </VCardText>

      <VCardText>
        <VProgressLinear
          :indeterminate="!firstResponseReceived || startDownload"
          :value="pourcentOfProgress"
          color="primary"
        ></VProgressLinear>

        <p v-if="!firstResponseReceived">
          {{ waitFirstAnswer }}
        </p>
        <p v-if="firstResponseReceived">
          {{ itemsFetched.length }}/{{ numberOfItemsToFetch }}
          {{ elementFetched }}
        </p>
        <p v-if="startDownload">
          {{ waitForDownload }}
        </p>
      </VCardText>

      <VCardActions class="mt-8">
        <div class="flex-grow-1"></div>
        <!-- <VBtn
          color="primary"
          depressed
          class="mr-5"
          :disabled="generating"
          @click="exportInventory"
        >
          <VIcon left> mdi-export </VIcon>
          {{ $t("export") }}
        </VBtn> -->
        <VBtn dark color="#ff5f85" class="mr-5" @click="$emit('input', false)">
          <VIcon left> mdi-close-circle </VIcon>
          {{ $t("cancel") }}
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import dayjs from "dayjs";
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);
import { exportSheetToExcel } from "@/utils/excel_writer";

import i18n from "@/setup/i18n";

export default {
  name: "DresskareBaseExportModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    grpcStream: {
      type: Function,
      required: true,
    },
    transformToArrayFunc: {
      type: Function,
      required: true,
    },
    sheetHeaders: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      default: () => {},
    },
    excelName: {
      type: String,
      default: "dresskare-excel",
    },
    fileName: {
      type: String,
      default: "dresskare-export",
    },
    modalTitle: {
      type: String,
      default: "Export",
    },
    waitFirstAnswer: {
      type: String,
      default: i18n.t("waitFirstAnswer"),
    },
    elementFetched: {
      type: String,
      default: i18n.t("elementFetched"),
    },
    waitForDownload: {
      type: String,
      default: i18n.t("waitForDownload"),
    },
    options: {
      type: Object,
      default: () => ({ "!cols": [] }),
    },
  },
  data() {
    return {
      generating: false,
      itemsFetched: [],
      numberOfItemsToFetch: 0,
      firstResponseReceived: false,
      startDownload: false,
    };
  },
  computed: {
    pourcentOfProgress() {
      if (!this.numberOfItemsToFetch) {
        return 0;
      }
      return (this.itemsFetched.length / this.numberOfItemsToFetch) * 100;
    },
  },
  created() {
    this.exportInventory();
  },
  methods: {
    async exportInventory() {
      this.generating = true;

      let metadata = {};
      metadata.filters = JSON.stringify(this.filters);

      for await (const response of this.grpcStream(undefined, {
        headers: metadata,
      })) {
        this.numberOfItemsToFetch = response.count;
        this.firstResponseReceived = true;

        for (const product of response.results) {
          let productFormated = await this.transformToArrayFunc(product);
          this.itemsFetched.push(productFormated);
        }

        if (this.itemsFetched.length === this.numberOfItemsToFetch) {
          this.startDownload = true;
          exportSheetToExcel(
            this.itemsFetched,
            this.sheetHeaders,
            this.excelName,
            this.fileName,
            this.options
          );
          this.$emit("input", false);
        }
      }
    },
  },
};
</script>


<i18n>
{
  "en": {
    "exportInventory": "Inventory export",
    "export": "Export",
    "cancel": "Cancel"
  },
  "fr": {
    "exportInventory": "Export de l'inventaire",
    "export": "Exporter",
    "cancel": "Annuler"
  }
}
</i18n>