const NewDepositIntroduction = () =>
  import(
    /* webpackChunkName: "NewDepositIntroduction" */ "@/components/new-deposit/NewDepositIntroduction"
  );

const NewDepositContact = () =>
  import(
    /* webpackChunkName: "NewDepositContact" */ "@/components/new-deposit/NewDepositContact"
  );

const NewDepositCriteria = () =>
  import(
    /* webpackChunkName: "NewDepositCriteria" */ "@/components/new-deposit/NewDepositCriteria"
  );

const NewDepositInfo = () =>
  import(
    /* webpackChunkName: "NewDepositInfo" */ "@/components/new-deposit/NewDepositInfo"
  );

const NewDepositContent = () =>
  import(
    /* webpackChunkName: "NewDepositContent" */ "@/components/new-deposit/NewDepositContent"
  );

const NewDepositSentDetails = () =>
  import(
    /* webpackChunkName: "NewDepositSentDetails" */ "@/components/new-deposit/NewDepositSentDetails"
  );

export default [
  {
    path: "",
    name: "NewDepositIntroduction",
    component: NewDepositIntroduction,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "contact/:customerUuid?",
    name: "NewDepositContact",
    component: NewDepositContact,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "criteria/:customerUuid",
    name: "NewDepositCriteria",
    component: NewDepositCriteria,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "deposit-info/:customerUuid",
    name: "NewDepositInfo",
    component: NewDepositInfo,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "deposit-content/:customerUuid",
    name: "NewDepositContent",
    component: NewDepositContent,
    meta: {
      isPublic: true,
    },
  },
  {
    path: "deposit-sent-details/:customerUuid",
    name: "NewDepositSentDetails",
    component: NewDepositSentDetails,
    meta: {
      isPublic: true,
    },
  },
];
