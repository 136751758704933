<template>
  <VTooltip v-if="getInitials" :disabled="!getInitials" bottom>
    <template #activator="{ on }">
      <VAvatar :color="color" :size="size" v-on="on">
        <img v-if="profilPicture" alt="Avatar" :src="getUrl(profilPicture)" />
        <span v-else class="white--text" :style="{ 'font-size': fontSize }">
          {{ getInitials }}
        </span>
      </VAvatar>
    </template>

    <slot name="tooltipContent">
      <span v-if="firstName || lastName"> {{ firstName }} {{ lastName }} </span>
      <span v-else-if="user"> {{ user.firstName }} {{ user.lastName }} </span>
      <span v-else-if="email">
        {{ email }}
      </span>
      <span v-else>{{ $t("project_manager_undefined") }}</span>
    </slot>
  </VTooltip>
</template>

<script>
export default {
  name: "DresskareBaseInitials",
  props: {
    user: {
      type: Object,
      default: null,
    },
    firstName: {
      type: String,
      default: "",
    },
    lastName: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#00ace8",
    },
    size: {
      type: [Number, String],
      default: "36",
    },
    fontSize: {
      type: String,
      default: "14px",
    },
    profilPicture: {
      type: String,
      default: "",
    },
    baseUrl: {
      type: String,
      default: "",
    },
  },
  computed: {
    getInitials() {
      if (this.email !== "") {
        const firstPart = this.email.substring(0, this.email.lastIndexOf("@"));
        const fields = firstPart.split(".");

        // Mail doesn't have the pattern first.last@test.com
        if (fields.length < 2) {
          return fields[0].toUpperCase;
        }
        return this.getInitialsFromName(
          firstPart.charAt(0),
          firstPart.charAt(1)
        );
      }

      // Possible to get initials from props firstName and lastName specified directly
      // or using the user props that contain a full user object
      if (this.firstName || this.lastName) {
        return this.getInitialsFromName(this.firstName, this.lastName);
      }

      if (this.user) {
        return this.getInitialsFromName(
          this.user.firstName,
          this.user.lastName
        );
      }
      return "";
    },
  },

  methods: {
    getInitialsFromName: (firstName, lastName) => {
      try {
        return (firstName.charAt(0) + lastName.charAt(0)).toUpperCase();
      } catch (_) {
        return "";
      }
    },

    getUrl(relativeUrl) {
      return `${this.baseUrl}${relativeUrl}`;
    },
  },

  // ----------------------- `i18n` option, setup locale info for component -----------------------
  i18n: {
    messages: {
      fr: {
        project_manager_undefined: "Pilote non défini",
      },
      en: {
        project_manager_undefined: "Project manager undefined",
      },
    },
  },
};
</script>
