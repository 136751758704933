import * as Sentry from "@sentry/vue";
import Vue from "vue";

export default function setupSentry(router, dsn, environment) {
  Sentry.init({
    Vue,
    dsn,
    environment,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.httpClientIntegration(),
      Sentry.replayIntegration(),
      Sentry.captureConsoleIntegration({ levels: ["warn", "error"] }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: [
      "localhost",
      // prod dresskare
      /^https:\/\/grpc\.app\.dresskare\.com/,
      /^https:\/\/grpc-web\.app\.dresskare\.com/,
      /^https:\/\/back\.app\.dresskare\.com/,
      // prod notification
      /^https:\/\/grpc\.notification\.app\.dresskare\.com/,
      /^https:\/\/grpc-web\.notification\.app\.dresskare\.com/,
      /^https:\/\/back\.notification\.app\.dresskare\.com/,

      // dev dresskare
      /^https:\/\/grpc\.dev\.app\.dresskare\.com/,
      /^https:\/\/grpc-web\.dev\.app\.dresskare\.com/,
      /^https:\/\/back\.dev\.app\.dresskare\.com/,
      // dev notification
      /^https:\/\/grpc\.dev\.notification\.app\.dresskare\.com/,
      /^https:\/\/grpc-web\.dev\.notification\.app\.dresskare\.com/,
      /^https:\/\/back\.dev\.notification\.app\.dresskare\.com/,
    ],

    // Capture Replay for 0% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0,
  });
}
