<template>
  <VRow class="mt-2 mr-4">
    <VSpacer />
    <VCol class="flex-grow-0" @click="goToProfile">
      <DresskareBaseInitials
        :first-name="currentUser.firstName"
        :last-name="currentUser.lastName"
        :profil-picture="currentUser.profilPicture"
        size="28"
        font-size="11px"
        :color="avatarColor"
      />
    </VCol>
  </VRow>
</template>

<script>
export default {
  name: "DresskareTopBar",
  props: {
    currentUser: {
      type: Object,
      required: true,
    },
    editProfileUrl: {
      type: [String, Object],
      required: true,
    },
    avatarColor: {
      type: String,
      default: "primary",
    },
  },
  methods: {
    goToProfile() {
      if (this.$route.name !== this.editProfileUrl) {
        this.$router.push(this.editProfileUrl);
      }
    },
  },
};
</script>

<style>

</style>