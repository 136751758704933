<template>
  <DresskareBaseFiltering
    :value="value"
    :options="options"
    :class="cssClasses"
    :combobox="true"
    :truncate-text="false"
    v-bind="$attrs"
    @search="searchOptions"
    @input="emitEvent"
  />
</template>

<script>
export default {
  name: "DresskareBaseCreatableFiltering",
  props: {
    value: {
      type: String,
      default: null,
    },
    cssClasses: {
      type: String,
      default: "pa-0 ma-0",
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    defaultOptions: {
      type: Array,
      default: () => [],
    },
    searchFunction: {
      type: Function,
      default: () => [],
    },
  },
  data() {
    return {
      options: [],
    };
  },
  created() {
    this.options = this.defaultOptions;
  },
  methods: {
    emitEvent(newValue) {
      let existing;

      if (!newValue) {
        existing = true;
      } else {
        existing = this.options.includes(newValue.toLowerCase());
      }

      this.$emit("input", {
        value: newValue,
        existing: existing,
      });
    },
    async searchOptions(search) {
      try {
        if (!search) {
          this.options = this.defaultOptions;
          return;
        }
        this.options = await this.searchFunction(search);
      } catch (error) {
        console.log("error:", error);
        this.options = [];
      }
    },
  },
};
</script>

